<template>
  <div class="account-already-exists">
      <b-container>
        <b-row>
          <b-col sm="12">
            <h1>Sorry about that - the user with an email you have tried to signup already exists.</h1>
            <p>{{login_option}}</p>
            <a class="btn gy-primary backHome" href="/login">Visit Login Page</a>
          </b-col>
        </b-row>
      </b-container>
  </div>
</template>

<script>
export default {
  name: 'account-already-exists',
  head () {
    return {
      title: 'Account Already Exists | ' + this.$store.getters["appName"],
      meta: [
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    }
   },
    data () {
      return {
        login_option: ''
      }
    },
    
    async mounted() {
      if (this.$route.query.conns) {
        const conn = this.$route.query.conns;
        if(conn === 'google-oauth2') {
          this.login_option = `Please login using Google option`;
        }
        if(conn === 'GoPass') {
          this.login_option = `Please login using Goodyear Employee option`;
        }
        if(conn === 'facebook') {
          this.login_option = `Please login using Facebook option`;
        }
        if(conn === 'windowslive') {
          this.login_option = `Please login using Microsoft Account option`;
        }
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.account-already-exists {
  background-color: #fff;
  min-height: 500px;
  padding-top: 50px;
  margin-bottom: 25px;
  text-align: center;
}
h1 {
  font-family: 'Barlow', Arial, sans-serif;
  font-weight: bold;
  font-size: 32px;
  color: #000;
}
.btn.gy-primary {
  color: #000;
}
</style>

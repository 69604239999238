<template>
<div class="terms">

    <meta name="viewport" content="width=device-width, initial-scale=1" />

    <head>
        <title>GoodyearPass</title>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.2/font/bootstrap-icons.css">
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
    </head>

    <div class="container-fluid">
        <div class="form">
            <div class="logoContainer">
                <img id="logo" alt="GoodyearPass Logo" src="https://gyb2cdev.z20.web.core.windows.net/images/GYPassLogo1.png">
            </div>

            <h4>Terms of Use:</h4>
            <div :class="{ 'termsParams': parameterExists === true, 'termsNoParams': parameterExists === false }">
                <p>
                    GOODYEARPASS PRIVACY POLICY AND TERMS OF USE
                </p>
                <p>
                    (Updated May 13, 2022)
                </p>
                <p>
                    INTRODUCTION
                </p>
                <p>
                    This Privacy Policy and Terms of Use (collectively, the “Policy”) governs your use of the GoodyearPass tool (“GoodyearPass”). Goodyear and its affiliates make a variety of web-based tools available to customers. GoodyearPass is a web-based access and identity management tool that allows you to access these Goodyear tools using a single sign-on (SSO) process. GoodyearPass also provides the option of signing on using your account information from third-party providers such as Google, Facebook, or Microsoft (“Third-Party Accounts”).
                </p>
                <p>
                    This Policy is designed to inform you of how GoodyearPass works, what information may be gathered during your use of GoodyearPass, what use may be made of that information, and the provisions that govern your use of GoodyearPass. By agreeing to the terms of this Policy, and/or separately by using GoodyearPass, you consent to the Policy, including, but not limited to, the collection, processing, disclosure and use of your information as described in the Privacy Policy. If you will use GoodyearPass on behalf of your employer, you represent that you have the power and authority to accept these Terms of Use on behalf of your employer. “You” or “User” as used in this Policy refers to both you and your employer, if any. Goodyear reserves the right to modify the terms of this Policy in its sole discretion, and your continued use of GoodyearPass after the modified Policy is available will constitute your consent to the revised Policy. Should you not wish to have your information gathered or used as described, and/or if you do not agree with any provision of this Policy, you should delete your GoodyearPass account.
                </p>
                <p>
                    PRIVACY POLICY
                </p>
                <p>
                    HOW DOES GOODYEARPASS WORK?
                </p>
                <p>
                    We send an invitation e-mail to new users of Goodyear applications. That email contains a unique sign up code a link to the GoodyearPass website. There you can paste the unique code into the appropriate field and establish a GoodyearPass account by following the other instructions found there. Once you create an account, any information you submit is processed on Goodyear-controlled systems, and is available to employees or agents of Goodyear who may access the information and use it as described below. Your information may be stored and processed on systems located both within and outside of the United States. (If you do not use your unique code to register within seven days, the code is deleted.) If you opt to authenticate using a Third-Party Account, you may see content from the relevant third party and you may be required to provide information to the third party.
                </p>
                <p>
                    WHAT INFORMATION DOES GOODYEARPASS COLLECT?
                </p>
                <p>
                    GoodyearPass collects information that you submit through it. When you register, GoodyearPass will collect identifying information, including your name, email address and authenticating information.
                </p>
                <p>
                    We do not use GoodyearPass to knowingly collect or solicit information from, or to market to, children under the age of 13. If a parent or guardian becomes aware that his or her child has provided us with information without their consent, he or she should delete the minor’s account, or contact us by visiting our website at <a href="https://goodyeardatarequest.ethicspoint.com">https://goodyeardatarequest.ethicspoint.com</a>, and we will delete the information within a reasonable time.
                </p>
                <p>
                    In addition, GoodyearPass may collect certain information automatically, such as your IP address, the type and unique mobile ID of your device, the type of your mobile operating system and browser you are using, your geolocation and information about how you are using GoodyearPass.
                </p>
                <p>
                    HOW IS YOUR PERSONALLY IDENTIFIABLE INFORMATION USED?
                </p>
                <p>
                    We will use your information solely for the purpose of access and identity management in connection with your use of other Goodyear tools. We will not use your information to contact you for marketing purposes. We may use your information to improve GoodyearPass and to improve your experience with it.
                </p>
                <p>
                    If you use a Third Party Account, we are required to pass your information back to the third-party provider that hosts your Third-Party Account in order to authenticate your identity and provide access to the Goodyear tools. Goodyear has no control over how that third-party provider treats your information. You should review the relevant privacy policy and terms of use from that provider if you have questions or concerns about how that provider processes your data.
                </p>
                <p>
                    Other than when you use Third-Party Accounts, we generally do not share your personally identifiable information with third parties. However, we may share or transfer your information (1) as required by law, such as to comply with a subpoena, warrant or similar legal process; (2) when we believe in good faith that the disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud or respond to a government request; or (3) to a successor to Goodyear’s business or assets when a transaction transfers Goodyear’s rights in GoodyearPass. We also may transfer or disclose anonymized or aggregated information to parties that assist us in designing, operating or improving GoodyearPass.
                </p>
                <p>
                    We will retain your information for as long as you use GoodyearPass and for a reasonable time thereafter. We also may retain your information in anonymized or aggregated form indefinitely. If you would like to delete your information, you can delete your account using GoodyearPass. Or you can request deletion by visiting our website at <a href="https://goodyeardatarequest.ethicspoint.com">https://goodyeardatarequest.ethicspoint.com</a>. Please be advised that when we delete your information per your request, you will no longer be a registered user, and as a result, the features and functions of GoodyearPass will no longer be available for your use.
                </p>
                <p>
                    HOW DO WE PROTECT YOUR PERSONALLY IDENTIFIABLE INFORMATION?
                </p>
                <p>
                    We are concerned with safeguarding your information. We provide physical, electronic and policy safeguards to protect information we collect, process and maintain. Please be aware that, although we endeavor to provide reasonable security for information we collect and use, no security system can prevent all unauthorized access to, use or disclosure of personal information.
                </p>
                <p>
                    HOW CAN YOU CONTACT US?
                </p>
                <p>
                    If you have any questions regarding the privacy or security of your information, or have questions about our policies or practices, please contact us at by visiting our website at <a href="https://goodyeardatarequest.ethicspoint.com">https://goodyeardatarequest.ethicspoint.com</a>.
                </p>
                <p>
                    * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
                </p>
                <p>
                    TERMS OF USE
                </p>
                <p>
                    Access to GoodyearPass
                </p>
                <p>
                    It is your responsibility to ensure your device meets all the necessary technical specifications to enable you to access and use GoodyearPass.
                </p>
                <p>
                    Data
                </p>
                <p>
                    Subject to the Privacy Policy, we (and/or our agents) may, in our sole discretion, collect, use, store, analyze, and/or disclose any and all data and/or information provided, generated, transferred, made available and/or input through GoodyearPass (collectively, “Data”), solely for the purposes set forth in the Privacy Policy above.
                </p>
                <p>
                    What you are allowed to do
                </p>
                <p>
                    You may use GoodyearPass only for your own individual use and only in accordance with these Terms of Use.
                </p>
                <p>
                    What you are not allowed to do
                </p>
                <p>
                    Except to the extent expressly set out in these Terms of Use, you are not allowed to:
                </p>
                <ul class="prohibited">
                    <li>share your account information with anyone, even your co-workers;</li>
                    <li>republish, redistribute or re-transmit GoodyearPass; </li>
                    <li>copy or store GoodyearPass other than for your use in accordance with these Terms of Use and as may occur incidentally in the normal course of use of your device; </li>
                    <li>store GoodyearPass on a server or other storage device connected to a network or create a database by systematically downloading and storing any data from GoodyearPass; </li>
                    <li>attempt to circumvent security or interfere with the proper working of GoodyearPass or any servers on which it is hosted; or</li>
                    <li>use GoodyearPass to create (whether for yourself or someone else) any product, application or service which seeks to provide the same or similar functionalities as GoodyearPass); </li>
                    <li>otherwise do anything that it is not expressly permitted by these Terms of Use. </li>
                </ul>
                <p>
                    You must use GoodyearPass only for lawful purposes (complying with all applicable laws and regulations), in a responsible manner, and not in a way that might damage our name or reputation or that of any of our associates or affiliates.
                </p>
                <p>
                    All rights granted to you under these Terms of Use will terminate immediately in the event that you are in breach of any provision of these Terms of Use.
                </p>
                <p>
                    Intellectual property rights
                </p>
                <p>
                    All intellectual property rights in GoodyearPass and in any content associated with GoodyearPass (including text, graphics, software, images, videos, sound, trademarks and logos) are owned by us or our licensors. Nothing in these Terms of Use gives you any rights in any intellectual property owned by us (or our licensors) and you acknowledge that you do not acquire any ownership rights by using GoodyearPass.
                </p>
                <p>
                    Operation of GoodyearPass
                </p>
                <p>
                    We cannot guarantee the continuous, uninterrupted or error-free operability of GoodyearPass. There may be times when all (or certain features or parts) of GoodyearPass become unavailable (whether on a scheduled or unscheduled basis) or are modified, suspended or withdrawn by us, in our sole discretion, for any reason and without notice to you. You agree that we will not be liable to you or to any third party for any unavailability, modification, suspension or withdrawal of GoodyearPass, or any features or parts of GoodyearPass. Without limiting the foregoing, GoodyearPass may discontinue use any or all Third-Party Accounts.
                </p>
                <p>
                    Disclaimer; Limitation of Liability
                </p>
                <p>
                    WE MAY CHANGE THE FORMAT AND CONTENT OF GOODYEARPASS FROM TIME TO TIME. YOU AGREE THAT, EXCEPT AS EXPRESSLY SET FORTH IN THIS POLICY, YOUR USE OF GOODYEARPASS IS ON AN “AS IS” AND “AS AVAILABLE” BASIS AND AT YOUR SOLE RISK.
                </p>
                <p>
                    WHILE WE ENDEAVOR TO MAKE SURE THAT ALL INFORMATION ASSOCIATED WITH GOODYEARPASS IS CORRECT, IT IS NOT INTENDED TO AMOUNT TO AUTHORITY OR ADVICE ON WHICH RELIANCE SHOULD BE PLACED. WE DO NOT MAKE ANY REPRESENTATIONS OR WARRANTIES, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR ANY PURPOSE, OR NON-INFRINGEMENT, AS TO GOODYEARPASS OR THE ACCURACY, COMPLETENESS, CURRENCY, CORRECTNESS, RELIABILITY, INTEGRITY, QUALITY, FITNESS FOR PURPOSE OR ORIGINALITY OF ANY INFORMATION ASSOCIATED WITH GOODYEARPASS. WE CANNOT AND DO NOT GUARANTEE THAT GOODYEARPASS WILL BE FREE FROM VIRUSES AND/OR OTHER CODE THAT MAY HAVE CONTAMINATING OR DESTRUCTIVE ELEMENTS. IT IS YOUR RESPONSIBILITY TO IMPLEMENT APPROPRIATE SECURITY SAFEGUARDS (INCLUDING ANTI-VIRUS AND OTHER SECURITY CHECKS) TO SATISFY YOUR PARTICULAR REQUIREMENTS AS TO SAFETY AND RELIABILITY.
                </p>
                <p>
                    TO THE FULLEST EXTENT PERMITTED BY LAW, YOU AGREE THAT NEITHER GOODYEAR NOR ANY SUCH SUBSIDIARY WILL HAVE ANY LIABILITY TO USER OF ANY KIND OR NATURE ARISING IN CONNECTION WITH GOODYEARPASS. IN NO EVENT WILL GOODYEAR OR ANY SUCH SUBSIDIARY BE LIABLE FOR ANY LOST REVENUE, PROFIT, OR LOST OR DAMAGED DATA, BUSINESS INTERRUPTION, UNAVAILABILITY OF PRODUCTS AND/OR SERVICES, OR FOR DIRECT, SPECIAL, INDIRECT, CONSEQUENTIAL, INCIDENTAL, OR PUNITIVE DAMAGES, HOWEVER CAUSED, EVEN IF GOODYEAR OR ANY SUCH SUBSIDIARY HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                </p>
                <p>
                    General
                </p>
                <p>
                    This Policy will be governed by and construed in accordance with the laws of the State of Ohio, USA without regard to choice of law principles. The sole and exclusive jurisdiction and venue for actions arising under this Policy will be the courts in the State of Ohio, USA. You consent to service of process in accordance with the rules of such courts, irrevocably submit to the jurisdiction of such courts, and waive any objection that you may have to either the jurisdiction or venue of such courts. Notwithstanding any choice of law provision or otherwise, the Uniform Computer Information Transactions Act (UCITA) and the United Nations Convention on the International Sale of Goods will not apply. This Policy constitutes the entire agreement between the parties with respect to its subject matter. Except as expressly provided herein, this Policy supersedes all previous written and previous or contemporaneous oral communications, representations, and agreements relating to the subject matter of this Policy.
                </p>
                <p>
                    If any term of this Policy is declared void or unenforceable by any court of competent jurisdiction, such provision will be modified, limited or eliminated to the minimum extent necessary and such declaration will have no effect on the remaining terms hereof, which will continue in full force and effect. The failure of either party to enforce any rights granted hereunder or to take action against the other party in the event of any breach hereunder will not be deemed a waiver by that party as to subsequent enforcement of rights or subsequent actions in the event of future breaches. This Policy may not be assigned or transferred, by operation of law, merger, change of control, or otherwise, without Goodyear’s prior written consent. Any action in violation of the foregoing will be void and without effect. We expressly reserve the right to assign this Policy. Nothing in this Policy will be construed to constitute or deem you as an agent, employee, representative, or partner of Goodyear. Nothing in this Policy will serve to limit our rights or your obligations under any dealer, wholesale distributor, or like agreement. It is the express wish of the parties that this Policy and all related documents be drafted in the English language. C’est la volonté expresse des parties que cette convention et tous documents y afférant soient rédigés en langue anglaise. You acknowledge that a breach of this Policy affecting Goodyear’s or its licensors’ rights in any confidential information, or intellectual property or other proprietary rights may cause irreparable injury to such party for which monetary damages would not be an adequate remedy, and the non-breaching party will be entitled to equitable relief (without a requirement to post a bond) in addition to any additional remedies it may have under this Policy, at law, or in equity.
                </p>
                <p>
                    Your personal information
                </p>
                <p>
                    Use of your personal information submitted to us (whether via our website or otherwise) is governed by the Privacy Policy stated above.
                </p>
                <p>
                    Contacting us
                </p>
                <p>
                    Please submit any questions you have about these Terms of Use or any problems concerning GoodyearPass by emailing us at support@goodyearpass.com.
                </p>
                <br>
            </div>
            <div id="attributeList" class="attr">
                <ul>
                    <li class="CheckboxMultiSelect extension_AgreedToTermsAndConditions_li">
                        <div class="attrEntry">
                            <div class="error itemLevel" role="alert"></div><input id="extension_AgreedToTermsAndConditions_true" name="extension_AgreedToTermsAndConditions" type="checkbox" v-model="isChecked" v-if="parameterExists"><label class="clickToAccept" label id="true_option" for="extension_AgreedToTermsAndConditions_true" v-if="parameterExists">Click to Accept Terms</label>
                        </div>
                    </li>
                </ul>
                <div class="buttons">
                    <button class="button" button id="continue" v-if="parameterExists" @click="acceptTermsOfService">Continue</button><button class="button" button id="cancel" v-if="parameterExists" @click="rejectTermsOfService">Reject</button>
                </div>
                <div v-if="showDialog">
                    <div class="dialog">
                        <p>Please agree to the Terms of Service before submitting.</p>
                        <button @click="hideDialog">OK</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="background-image"></div>

</div>
</template>

<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.2/font/bootstrap-icons.css");
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    font-family: 'Barlow', sans-serif !important;
    color: #0D2F42 !important;
}

.background-image {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -2;
    background-image: url('https://gyb2cdev.z20.web.core.windows.net/images/B2C_GradientBackground.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;
}

.dialog {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.extension_DateAgreedToTermsAndConditions_li {
    display: none;
}

.helpLink {
    display: none;
}

.heading {
    display: none;
}

h1 {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
}

h3 {
    font-size: 120%;
}

#extension_AgreedToTermsAndConditions_true {
    height: 12px;
    width: 20px;
    margin: auto;
    cursor: pointer;
}

#extension_AgreedToTermsAndConditions_label,

#attributeList {
    margin-top: 5%;
}

#extension_AgreedToTermsAndConditions_label {
    display: none;
}

ul:not(.prohibited) {
    list-style-type: none;
    padding: 0%;
}

.logoContainer {
    display: flex;
    justify-content: center;
    margin: 30px 0px;
}

#logo {
    width: 200px;
}

.error {
    color: red;
}

.button {
    border-radius: .5rem;
    height: 35px;
    width: 90px;
    margin: 2%;
    background-color: #0D2F42;
    border: 2px solid #0D2F42;
    color: white;
    font-size: 18px;
    font-weight: 600;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
    cursor: pointer;
    font-family: Barlow, sans-serif;
}

.clickToAccept {
    font-size: 18px;
    font-family: Barlow, sans-serif;
}

@media only screen and (min-width: 0px) {
    .form {
        text-align: center;
        position: relative;
        display: inline-block;
        height: 100vh;
        max-height: calc(100vh - 165px);
        overflow-y: auto;
        margin-top: 15px;
        margin-bottom: 15px;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        font-size: 16px;
        line-height: 1.4;
        box-sizing: border-box;
        padding: 10px;
        background-color: #fff;
        color: #333;
        width: 100%;
        top: 100%;
        left: 50%;
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        border-radius: 10px;
    }

    .termsParams {
        margin: auto;
        width: 85%;
        height: calc(100vh - 400px);
        font-size: 100%;
        font-family: Barlow, sans-serif;
        border: 1px solid #4e4e4e;
        overflow-y: auto;
        overflow-x: hidden;

    }

    .termsNoParams {
        margin: auto;
        width: 85%;
        height: calc(100vh - 300px);
        font-size: 100%;
        font-family: Barlow, sans-serif;
        border: 1px solid #4e4e4e;
        overflow-y: auto;
        overflow-x: hidden;

    }
}

@media only screen and (min-width: 281px) {
    .form {
        text-align: center;
        position: relative;
        display: inline-block;
        height: 100vh;
        max-height: calc(100vh - 170px);
        overflow-y: auto;
        margin-top: 15px;
        margin-bottom: 15px;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        font-size: 16px;
        line-height: 1.4;
        box-sizing: border-box;
        padding: 27px;
        background-color: #fff;
        color: #333;
        width: 100%;
        top: 100%;
        left: 50%;
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        border-radius: 10px;
    }

    .termsParams {
        margin: auto;
        width: 85%;
        height: calc(100vh - 400px);
        font-size: 100%;
        font-family: Barlow, sans-serif;
        border: 1px solid #4e4e4e;
        overflow-y: auto;
        overflow-x: hidden;

    }

    .termsNoParams {
        margin: auto;
        width: 85%;
        height: calc(100vh - 300px);
        font-size: 100%;
        font-family: Barlow, sans-serif;
        border: 1px solid #4e4e4e;
        overflow-y: auto;
        overflow-x: hidden;

    }
}

@media only screen and (min-width: 311px) {
    .form {
        text-align: center;
        position: relative;
        display: inline-block;
        height: 100vh;
        max-height: calc(100vh - 145px);
        overflow-y: auto;
        margin-top: 15px;
        margin-bottom: 15px;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        font-size: 16px;
        line-height: 1.4;
        box-sizing: border-box;
        padding: 27px;
        background-color: #fff;
        color: #333;
        width: 100%;
        top: 100%;
        left: 50%;
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        border-radius: 10px;
    }

    .termsParams {
        margin: auto;
        width: 85%;
        height: calc(100vh - 400px);
        font-size: 100%;
        font-family: Barlow, sans-serif;
        border: 1px solid #4e4e4e;
        overflow-y: auto;
        overflow-x: hidden;

    }

    .termsNoParams {
        margin: auto;
        width: 85%;
        height: calc(100vh - 300px);
        font-size: 100%;
        font-family: Barlow, sans-serif;
        border: 1px solid #4e4e4e;
        overflow-y: auto;
        overflow-x: hidden;

    }
}

@media only screen and (min-width: 500px) {
    .form {
        text-align: center;
        position: relative;
        display: inline-block;
        height: 100vh;
        max-height: calc(100vh - 145px);
        overflow-y: auto;
        margin-top: 15px;
        margin-bottom: 15px;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        font-size: 16px;
        line-height: 1.4;
        box-sizing: border-box;
        padding: 0px;
        background-color: #fff;
        color: #333;
        width: 100%;
        top: 100%;
        left: 50%;
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        border-radius: 10px;
    }

    .termsParams {
        margin: auto;
        width: 85%;
        height: calc(100vh - 400px);
        font-size: 100%;
        font-family: Barlow, sans-serif;
        border: 1px solid #4e4e4e;
        overflow-y: auto;
        overflow-x: hidden;

    }

    .termsNoParams {
        margin: auto;
        width: 85%;
        height: calc(100vh - 300px);
        font-size: 100%;
        font-family: Barlow, sans-serif;
        border: 1px solid #4e4e4e;
        overflow-y: auto;
        overflow-x: hidden;

    }
}

@media only screen and (min-width: 577px) {
    .form {
        text-align: center;
        position: relative;
        display: inline-block;
        height: 100vh;
        max-height: calc(100vh - 125px);
        overflow-y: auto;
        margin-top: 15px;
        margin-bottom: 15px;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        font-size: 16px;
        line-height: 1.4;
        box-sizing: border-box;
        padding: 0px;
        background-color: #fff;
        color: #333;
        width: 100%;
        top: 100%;
        left: 50%;
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        border-radius: 10px;
    }

    .termsParams {
        margin: auto;
        width: 85%;
        height: calc(100vh - 400px);
        font-size: 100%;
        font-family: Barlow, sans-serif;
        border: 1px solid #4e4e4e;
        overflow-y: auto;
        overflow-x: hidden;

    }

    .termsNoParams {
        margin: auto;
        width: 85%;
        height: calc(100vh - 300px);
        font-size: 100%;
        font-family: Barlow, sans-serif;
        border: 1px solid #4e4e4e;
        overflow-y: auto;
        overflow-x: hidden;

    }
}

@media only screen and (min-width: 614px) {
    .form {
        text-align: center;
        position: relative;
        display: inline-block;
        /* max-height: calc(100vh - 105px); this touched the stsrt of footer */
        max-height: calc(100vh - 125px);
        margin-top: 15px;
        margin-bottom: 15px;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        font-size: 16px;
        line-height: 1.4;
        box-sizing: border-box;
        padding: 6px;
        background-color: #fff;
        color: #333;
        width: 600px;
        top: 100%;
        left: 50%;
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        border-radius: 10px;
    }

    .termsParams {
        margin: auto;
        width: 85%;
        height: calc(100vh - 400px);
        font-size: 100%;
        font-family: Barlow, sans-serif;
        border: 1px solid #4e4e4e;
        overflow-y: auto;
        overflow-x: hidden;

    }

    .termsNoParams {
        margin: auto;
        width: 85%;
        height: calc(100vh - 300px);
        font-size: 100%;
        font-family: Barlow, sans-serif;
        border: 1px solid #4e4e4e;
        overflow-y: auto;
        overflow-x: hidden;

    }
}
</style>

<script>
import {
    domain
} from '../authConfig';
export default {
    data() {
        return {
            isChecked: false,
            showDialog: false,
            parameterExists: false
        };
    },
    mounted() {
        const urlParams = new URLSearchParams(window.location.search);

        // Check if the parameter exists in the URL
        if (urlParams.has('fromAuth0')) {
            this.parameterExists = true;
        }
    },
    methods: {
        async acceptTermsOfService() {
            if (!this.isChecked) {
                this.showWarning = true;
                this.showDialog = true;
            } else {
                this.showWarning = false;

                // Redirect the user to continue authentication with the state parameter
                const state = this.$route.query.state;
                const stateEndpoint = `/continue?state=${state}`
                const redirectUrl = "https://" + domain + stateEndpoint

                window.location.href = redirectUrl;
            }
        },
        hideDialog() {
            this.showDialog = false;
        },
        async rejectTermsOfService() {
            // Redirect the user to access-denied
            this.$router.push("/access-denied")
        }

    }
};
</script>

<template>
    <div class="footer-text">
        © {{new Date().getFullYear()}} The Goodyear Tire & Rubber Company <span style="margin: 0 10px"> | </span>
        <a class="gy-footer-link" href="/termsofuse" rel="noopener">Terms & Conditions and Privacy Policy</a>
    </div>
</template>

<style scoped>
.footer-text {
    font-size: 14px;
    font-weight: 400;
    color: #0D2F42;
    text-align: center;
}
</style>
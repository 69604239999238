import Vue, { createApp } from 'vue'
import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import { domain, clientId } from './authConfig'
import { Auth0Plugin } from './auth'
import { VueHeadMixin, createHead } from '@unhead/vue'
import VueDOMPurifyHTML from 'vue-dompurify-html';

import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import '@/assets/styles/style.css'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

const app = createApp({
  ...App
})

app.use(store);
app.use(router);

const head = createHead()
app.mixin(VueHeadMixin)
app.use(head)
app.use(VueDOMPurifyHTML);

app.directive('b-tooltip', VBTooltip)

app.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
      appState?.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
})

app.mount('#app');


const alertsState = {
  alerts: [],
  alertIdSeq: 1,
}

// Actions 
const actions = {
  openAlert({ state, dispatch, commit }, { alertVariant, content }) {

    const alert = {
      id: state.alertIdSeq,
      variant: alertVariant,
      content,
    }
    dispatch('incrementAlertIdSeq');

    commit('OPEN_ALERT', alert);

    setTimeout(() => {
      dispatch('closeAlert', alert.id);
    }, 10000)
  },
  openSuccessAlert({ dispatch }, { content }) {
    dispatch('openAlert', { alertVariant: 'success', content });
  },
  openErrorAlert({ dispatch }, { content }) {
    dispatch('openAlert', { alertVariant: 'error', content });
  },
  closeAlert({ commit }, alertId) {
    commit('CLOSE_ALERT', alertId);
  },
  incrementAlertIdSeq({ commit }) {
    commit('INCREMENT_ALERT_ID_SEQ');
  },
}

// Mutations
const mutations = {
  OPEN_ALERT(state, alert) {
    state.alerts.push(alert);
    state.alerts = [...state.alerts];
  },
  CLOSE_ALERT(state, alertId) {
    let index = state.alerts.findIndex(alert => alert.id === alertId);

    if (index !== -1) {
      state.alerts.splice(index, 1);
      state.alerts = [...state.alerts];
    }
  },
  INCREMENT_ALERT_ID_SEQ(state) {
    state.alertIdSeq++;
  },
}

// Getters 
const getters = {
  alerts(state) {
    return state.alerts;
  }
}

export default {
  namespaced: true,
  state: alertsState,
  actions,
  mutations,
  getters,
}
import { createRouter, createWebHistory } from 'vue-router'

import error404 from '@/components/404'
import AccessDenied from '@/components/AccessDenied'
import Home from '@/components/Home'
import Welcome from '@/components/Welcome'
import SignUp from '@/components/SignUp'
import Login from '@/components/Login'
import TermsOfUse from '@/components/TermsOfUse'
import Logout from '@/components/Logout'
import { getAuthInstance } from '@/auth'
import AccountAlreadyExists from '@/components/AccountAlreadyExists'
import ConsentRequired from '@/components/ConsentRequired'
import SignUpCompleted from '@/components/SignUpCompleted'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/welcome',
      name: 'Welcome',
      component: Welcome
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/termsofuse',
      name: 'TermsOfUse',
      component: TermsOfUse,
    },
    {
      path: '/signup',
      name: 'SignUp',
      component: SignUp
    },
    {
      path: '/logout',
      name: 'Logout',
      component: Logout
    },
    {
      path: '/404-error',
      name: 'error-404',
      component: error404
    },
    {
      path: '/access-denied',
      name: 'access-denied',
      component: AccessDenied
    },
    {
      path: '/account-already-exists',
      name: 'account-already-exists',
      component: AccountAlreadyExists
    },
    {
      path: '/consent-required',
      name: 'consent-required',
      component: ConsentRequired
    },
    {
      path: '/signup-completed',
      name: 'signup-completed',
      component: SignUpCompleted
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/404-error'
    }
  ]
})

router.beforeEach(async (to, _from, next) => {

  // Skip auth0 initialization for static pages to decrease loading time after redirect
  if (to.name === 'consent-required' || to.name === 'signup-completed') {
    next();
    return;
  }

  const authInstance = getAuthInstance();

  if (!authInstance.auth0Client) {
    await authInstance.initialize();
  }

  if (to.name === 'error-404') {
    next();
  } else if (to.name === 'Logout') {
    if (authInstance.isAuthenticated) {
      // user logged in - log out
      await authInstance.logout();
      next(false);
    } else {
      next();
    }
  } else if (to.name === 'Login') {
    if (authInstance.isAuthenticated) {
      // user logged in - send to main page
      next({ name: 'Home' })
    } else {
      // user not logged in
      await authInstance.loginWithRedirect();
    }
  } else if (to.name === 'SignUp') {
    if (authInstance.isAuthenticated) {
      // user logged in - send to main page
      next({ name: 'Home' });
    } else {
      // user not logged in - send to signup
      let authorizationParams = { screen_hint: "signup" };

      if (to.query.invite_app_id) {
        authorizationParams.redirect_uri = encodeURI(`${window.location.origin}/signup-completed?invite_app_id=${to.query.invite_app_id}`);
      }

      await authInstance.loginWithRedirect({ authorizationParams });
    }
  } else if (to.name === 'Welcome') {
    if (authInstance.isAuthenticated) {
      // user logged in - send to main page
      next({ name: 'Home' })
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
<template>
  <div class="user-sidebar-wrapper">
    <div class="user-sidebar">
      <div class="sidebar-logo"><img :src="require('@/assets/GYPass_Logo_White.png')" alt="GoodyearPass logo" width="120" /></div>
      <div class="userIcon">{{ $store.state.user.initials }}</div>
      <div class="userName">{{ $store.state.user.displayName }}</div>
      <div class="userEmail">{{ userEmail }}</div>

      <div class="sidebar-buttons-wrapper">
        <div class="editProfileBtn">
          <button class="go-to-profile-btn gtm-leftpanel" v-if="$auth.isAuthenticated" v-on:click="openSettings()">Go to my account →</button>
        </div>
        <div class="logoutButton-wrapper">
          <button class="logoutButton" v-if="$auth.isAuthenticated" v-on:click="$auth.logout()">Sign Out</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'Sidebar',
    head () {
      return {
        title: this.$store.getters["appName"]
      }
    },
    data () {
      return {
        userEmail:''
      }
    },
    async mounted() {
      let tokenClaims = await this.$auth.getIdTokenClaims();
      if (tokenClaims) {
        this.userEmail = tokenClaims.email;
      }
    },
    methods : {
      openSettings: function() {
        this.$store.state.openSettings = true;
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .user-sidebar-wrapper {
    height: 100%;
    width: 100%;
    transition: 0.25s;
    padding-right: 20px;
  }

  .user-sidebar {
    align-self: flex-end;
    height: 100%;
    width: 100%;
    margin-right: 15%;
    background-color: #0D2F42;
    border-radius: 0px 50px 0px 0px;
    bottom: 0px;
  }

  .sidebar-logo {
    display: block;
    width: 120px;
    margin: auto;
    padding-top: 40px;
  }

  .userIcon {
    width: 80px;
    height: 80px;
    background-color: #FEDB00;
    color: #0D2F42;
    font-size: 38px;
    font-weight: 600;
    line-height: 76px;
    text-align: center;
    border-radius: 80px;
    margin: auto;
    margin-top: 30px;
    transition: 0.25s;
  }

  .userName {
    font-size: 1em;
    font-weight: 500;
    color: white;
    text-align: center;
    margin-top: 5px;
    max-width: 100%;
    overflow-x: hidden;
  }

  .userEmail {
    font-size: 14px;
    font-weight: 300;
    color: white;
    text-align: center;
    margin-top: 5px;
    padding: 0px 15px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sidebar-buttons-wrapper {
    width: 100%;
  }

  .editProfileBtn {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }

  .go-to-profile-btn {
    color: white;
    background: none;
    border: none;
    font-size: 16px;

    transition: 0.25s;
  }
  .go-to-profile-btn:hover {
    margin-left: 10px;
    opacity: 0.5;
  }

  .logoutButton-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  .logoutButton {
    font-size: 16px;
    font-weight: 600;
    background-color: white;
    border: white 2px solid;
    color: #0D2F42;
    border-radius: 20px;
    padding: 4px 14px;
    transition: 0.20s;
  }

  .logoutButton:hover {
    background-color: #0D2F42;
    border: white 2px solid;
    color: white;
  }

  /* Galaxy Fold */
  @media only screen and (max-width: 281px) {
    .user-sidebar-wrapper {
      display: none;
    }
  }

  @media only screen and (min-width: 281px) and (max-width: 600px) {
    .user-sidebar-wrapper {
      display: none;
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .user-sidebar-wrapper {
      display: none;
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .user-sidebar-wrapper {
      display: block;
    }
  }
</style>
<template>
    <div class="consent-required">
        <b-container>
            <b-row>
                <b-col sm="12">
                    <h1>You need to give GoodyearPass consent in order to access GoodyearPass.</h1>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
  
<script>
export default {
    name: 'consent-required',
    head() {
        return {
            title: 'Consent Required | ' + this.$store.getters["appName"],
            meta: [
                { name: 'robots', content: 'noindex, nofollow' }
            ]
        }
    },
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.consent-required {
    background-color: #fff;
    min-height: 500px;
    padding-top: 50px;
    margin-bottom: 25px;
    text-align: center;
}

h1 {
    font-family: 'Barlow', Arial, sans-serif;
    font-weight: bold;
    font-size: 32px;
    color: #000;
}
</style>
  
<template>
  <div class="welcome" role="main">
    <div class="background-image"></div>
    <b-container>
      <b-row class="intro-wrapper">
        <b-col>
          <img :src="require('@/assets/GYPass_Logo_Blue.png')" width="300" alt="GoodyearPass Logo Dark Blue" />
          <p>GoodyearPass is a single-sign-on web-based tool to allow you to access Goodyear applications. To sign up, contact your Goodyear sales rep.</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="loginButton-wrapper">
          <button class="loginButton" v-on:click="$auth.loginWithRedirect()">Sign In</button>
        </b-col>
      </b-row>

    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Welcome',
  head () {
    return {
      title: 'Sign In | ' +  this.$store.getters["appName"]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.background-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -2;
  background-image: url('../assets/images/background/B2C_Background-md.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
}
.intro-wrapper {
  text-align: center;
  margin-top: 50px;
}
.intro-wrapper p {
  margin-top: 15px;
}
h1 {
  display:block;
}
.loginButton-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 25px;
}

.loginButton {
  font-size: 16px;
  font-weight: 600;
  background-color: white;
  border: #0D2F42 2px solid;
  color: #0D2F42;
  border-radius: 20px;
  padding: 4px 14px;
  transition: 0.20s;
  min-width: 200px;
}

.loginButton:hover {
  color: #fff;
  background-color: #0D2F42;
}


@media (max-width: 500px) {
  .background-image {
    background-image: url('../assets/images/background/B2C_Background-xs.png');
  }
}
@media (min-width: 501px) and (max-width: 768px) {
  .background-image {
    background-image: url('../assets/images/background/B2C_Background-sm.png');
  }
}
@media (min-width: 1281px) and (max-width: 1600px) {
  .background-image {
    background-image: url('../assets/images/background/B2C_Background-lg.png');
  }
}
@media (min-width: 1601px) and (max-width: 2500px ) {
  .background-image {
    background-image: url('../assets/images/background/B2C_Background-xl.png');
  }
}
@media (min-width: 2501px) {
  .background-image {
    background-image: url('../assets/images/background/B2C_Background.png');
  }
}

</style>
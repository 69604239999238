<template>
    <div class="signup-completed">
        <div class="signup-completed__content">
            <h1>Thank you.<br /> Your GoodyearPass Registration is now complete.</h1>
            <hr />
            <p>Click below to proceed to the application, or view all of your Goodyear applications in the
                GoodyearPass portal.</p>
            <p><em>Note that it may take your account a few minutes to setup, so you may not be
                    able to login to your applications immediately.</em></p>
            <div class="link mt-50px">
                <button class="linkButton" v-on:click="$router.push('/')">GoodyearPass Portal</button>
            </div>
            <div v-if="inviteAppName && inviteAppUrl" class="link mt-30px">
                <button class="linkButton mt-20px" v-on:click="goToInviteApp">{{ inviteAppName }}</button>
            </div>
        </div>
    </div>
</template>
  
<script>
import axios from 'axios';
import { publicApiPath } from '../authConfig';

export default {
    name: 'signup-completed',
    data() {
        return {
            inviteAppName: null,
            inviteAppUrl: null,
        }
    },
    head() {
        return {
            title: 'Registration Completed | ' + this.$store.getters["appName"],
            meta: [
                { name: 'robots', content: 'noindex, nofollow' }
            ]
        }
    },
    async mounted() {
        if (this.$route.query.invite_app_id) {
            try {
                let { data: appLinkResponse } = await axios({url: `/applications/${this.$route.query.invite_app_id}/link`, baseURL: publicApiPath });

                this.inviteAppName = appLinkResponse.result.name;
                this.inviteAppUrl = appLinkResponse.result.loginUri;
            } catch (error) {
                console.log(error);
            }
        }
    },
    methods: {
        goToInviteApp: function () {
            window.open(this.inviteAppUrl, '_blank').focus();
        }
    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.signup-completed {
    background-color: #fff;
    min-height: 500px;
    padding-top: 50px;
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
}

.signup-completed__content {
    width: 550px;
    text-align: center;
}

h1 {
    font-family: 'Barlow', Arial, sans-serif;
    font-weight: bold;
    font-size: 32px;
    color: #000;
}

.linkButton {
    font-size: 16px;
    font-weight: 600;
    background-color: white;
    border: #0D2F42 2px solid;
    color: #0D2F42;
    border-radius: 20px;
    padding: 4px 14px;
    transition: 0.20s;
    min-width: 200px;
    display: block;
}

.linkButton:hover {
    color: #fff;
    background-color: #0D2F42;
}

.link {
    display: flex;
    justify-content: center;
}

.mt-20px {
    margin-top: 20px;
}

.mt-50px {
    margin-top: 50px;
}
</style>
  
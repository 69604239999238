// store/index.js

import { createStore } from 'vuex'

import alerts from './alerts.store';

export const store = createStore({
  state: {
    appName: 'GoodyearPass',
    signInInit: false,
    postSignUp: false,
    token: '',
    openSettings: false,
    currentDomain: '',
    appInsightsConnString: '',
    user: {
      auth: false,
      displayName: '',
      initials: '',
      firstName: '',
      lastName: '',
    },
    userNotFound: false,
  },
  getters: {
    appName: state => {
      return state.appName
    },
    signInInit: state => {
      return state.signInInit
    },
    signInInitLocal: state => {
      if (localStorage.getItem('signInInit')) {
        state.signInInit = true
        return true
      }
      state.signInInit = false
      return false
    },
    postSignUp: state => {
      return state.postSignUp
    },
    postSignUpLocal: state => {
      if (localStorage.getItem('postSignUp')) {
        state.postSignUp = true
        return true
      }
      state.postSignUp = false
      return false
    },
    auth: state => {
      return state.user.auth
    },
    userNotFound: state => {
      return state.userNotFound
    },
  },
  // Mutations are the only way to properly change the value of the state object. 
  // An important detail to note is that mutations must be synchronous.
  mutations: {
    setSignInInit(state, payload) {
      localStorage.setItem('signInInit', payload);
      state.signInInit = payload;
    },
    setPostSignUp(state, payload) {
      localStorage.setItem('postSignUp', payload);
      state.postSignUp = payload;
    },
    clearStore(state) {
      if (localStorage.getItem('signInInit')) {
        localStorage.removeItem('signInInit');
        state.signInInit = false;
      }
      if (localStorage.getItem('postSignUp')) {
        localStorage.removeItem('postSignUp');
        state.postSignUp = false;
      }
    },
    setToken(state, payload) {
      state.token = payload;
    },
    changeAuth(state, payload) {
      let isAuth = payload.isAuthenticated;
      state.user.auth = isAuth;
    },
    setUserName(state, payload) {
      state.user.displayName = payload;
    },
    setUserInitials(state, payload) {
      state.user.initials = payload;
    },
    setUserFirstName(state, payload) {
      state.user.firstName = payload;
    },
    setUserLastName(state, payload) {
      state.user.lastName = payload;
    },
    setUserNotFound(state, payload) {
      state.userNotFound = payload;
    },
    updateCurrentDomain(state, payload) {
      state.currentDomain = payload
    },
    setAppInsightsConnString(state, payload) {
      state.appInsightsConnString = payload;
    },
  },
  actions: {
    updateAppInsightsConnString({ commit }) {
      const currentDomain = window.location.origin;

      commit('updateCurrentDomain', currentDomain);

      const appInsightsConnStringOptions = [
        // Local
        {
          domains: [process.env.VUE_APP_GYPASS_DOMAIN_LOCAL],
          connString: process.env.VUE_APP_APP_INSIGHTS_DEV
        },
        // Dev
        {
          domains: [process.env.VUE_APP_GYPASS_DOMAIN_DEV],
          connString: process.env.VUE_APP_APP_INSIGHTS_DEV
        },
        // Test
        {
          domains: [process.env.VUE_APP_GYPASS_DOMAIN_TEST],
          connString: process.env.VUE_APP_APP_INSIGHTS_TEST
        },
        // Prod
        {
          domains: [process.env.VUE_APP_GYPASS_DOMAIN_PROD],
          connString: process.env.VUE_APP_APP_INSIGHTS_PROD
        },
      ]

      let connString = appInsightsConnStringOptions[0].connString; // default
      commit("setAppInsightsConnString", connString);

      // loop to see which domain matches current domain
      for (let appInsightsConnStringOption of appInsightsConnStringOptions) {
        for (let domain of appInsightsConnStringOption.domains) {
          if (domain.indexOf(currentDomain.toLowerCase()) !== -1) {
            connString = appInsightsConnStringOption.connString;
            commit("setAppInsightsConnString", connString);
          }
        }
      }
    },
  },
  modules: {
    alerts,
  },
});
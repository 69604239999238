<template>
  <div class="alert-wrapper">
    <Alert v-for=" alert in $store.state.alerts.alerts" :variant="alert.variant" @close="closeAlert(alert.id)">
      <div v-dompurify-html="alert.content"></div>
    </Alert>
  </div>
</template>

<script>
import Alert from '../components/Alert';

export default {
  name: "Alerts",
  components: {
    Alert,
  },
  methods: {
    closeAlert: function (alertId) {
      this.$store.dispatch('alerts/closeAlert', alertId);
    },
  },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.alert-wrapper {
  position: absolute;
  width: 300px;
  right: 0;
  top: 0;
  z-index: 9000;
}
</style>

<template>
  <div class="access-denied">
      <b-container>
        <b-row>
          <b-col sm="12">
            <h1>Sorry about that - you do not have the correct permissions to access the portal.</h1>
            <p>Contact the application administrator and request to be invited in GoodyearPass.</p>
          </b-col>
        </b-row>
        <hr>
        <b-row class="warn-text-wrapper">
          <b-col sm="12">
            <h2>Important Note for New GoodyearPass Users</h2>
            <i>*Please disregard if you are not currently signing up</i><br><br>
            <p>If you are a new GoodyearPass user and are experiencing unexpected access denied issues during the sign-up process, please follow these steps:</p>
            <ul>
              <li>Try a Fresh Browser Window or Incognito Mode</li>
              <div class="or">OR</div>
              <li>Log out of other accounts (other than the desired account you are attempting to sign up with) and clear your browser's cache</li>
            </ul>
          </b-col>
        </b-row>
      </b-container>
  </div>
</template>

<script>
export default {
  name: 'access-denied',
  head () {
    return {
      title: 'Access Denied | ' + this.$store.getters["appName"],
      meta: [
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    }
   }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.access-denied {
  background-color: #fff;
  min-height: 500px;
  padding-top: 50px;
  margin-bottom: 25px;
  text-align: center;
}
h1 {
  font-family: 'Barlow', Arial, sans-serif;
  font-weight: bold;
  font-size: 32px;
  color: #000;
}
.btn.gy-primary {
  color: #000;
}

.warn-text-wrapper {
  text-align: left;
}

.warn-text-wrapper h2 {
  font-size: 20px;
  font-weight: 700;
}

.warn-text-wrapper i {
  font-size: 14px;
  font-weight: 400;
}

.warn-text-wrapper p {
  font-size: 15px;
  font-weight: 500;
}

.warn-text-wrapper ul {
  list-style-type: decimal;
}

.warn-text-wrapper .or {
  font-weight: 600;
}
</style>
